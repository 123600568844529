import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Indent = makeShortcode("Indent");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Callout = makeShortcode("Callout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Liquidation`}</h1>
    <p>{`Liquidation is the process of selling collateral to cover the amount of Dai a user has generated from their Vault.`}</p>
    <p>{`Liquidation helps to ensure that Dai is always backed by an appropriate amount of collateral by closing-out Vaults that are under their minimum required Collateralization Ratio for their given collateral type.`}</p>
    <h2>{`How Liquidation Works`}</h2>
    <hr></hr>
    <p>{`A Vault can be liquidated if the value of its collateral falls below the required minimum level, called the Liquidation Ratio.`}</p>
    <p>{`To make sure that the required surplus of collateral exists at all times, a class of users called Keepers are incentivized to maintain a constant watch for Vaults that become under-collateralized.`}</p>
    <p>{`During the Liquidation process, enough collateral is sold to cover the debt along with a Liquidation Penalty, leaving the remaining collateral available for withdrawal.`}</p>
    <h2>{`Liquidation Ratio`}</h2>
    <hr></hr>
    <p>{`The Liquidation Ratio is the minimum required collateralization level for each Vault type before it is considered undercollateralized and subject to liquidation.`}</p>
    <p>{`The Maker Protocol’s oracles provide the system with pricing data that is used to track Vaults for when their Liquidation Ratio is breached. Once breached, they are available for Liquidation.`}</p>
    <p>{`Each Vault type’s Liquidation Ratio is determined by a combination of the collateral’s risk profile and the Stability Fee. There may be multiple Vault types for each collateral, with varying Liquidation Ratios and Stability Fees.`}</p>
    <p>{`The Liquidation Ratio of a Vault is typically displayed on front-ends that offer Vaults.`}</p>
    <Indent pad={4} mdxType="Indent">
      <Column mdxType="Column">
        <Box mdxType="Box">
          <p><strong parentName="p">{`To calculate a Liquidation Ratio, simply use the following formula:`}</strong></p>
          <p>{`Liquidation Ratio = (Collateral Amount x Collateral Price) ÷ Generated Dai × 100`}</p>
        </Box>
      </Column>
    </Indent>
    <Callout icon="lightbulb" mdxType="Callout">
      <h4>{`Example`}</h4>
      <p>{`For example, a Vault with a 150% Liquidation Ratio will require a minimum $1.50 of collateral value for every $1 of Dai generated.`}</p>
      <p>{`If the value of the collateral falls to ≤ $1.49 it will be Liquidated to cover the generated Dai in addition to a fee called the Liquidation Penalty.`}</p>
    </Callout>
    <h2>{`Liquidation Price`}</h2>
    <hr></hr>
    <p>{`The Liquidation Price is the price at which a Vault becomes vulnerable to liquidation.`}</p>
    <p>{`Vault owners can lower their liquidation price by adding more collateral or returning Dai to the Vault.`}</p>
    <p>{`The Liquidation Price of a Vault is typically displayed on front-ends that offer Vaults.`}</p>
    <Indent pad={4} mdxType="Indent">
      <Column mdxType="Column">
        <Box mdxType="Box">
          <p><strong parentName="p">{`To calculate a Liquidation Price, simply use the following formula:`}</strong></p>
          <p>{`Liquidation Price = (Generated Dai `}{`*`}{` Liquidation Ratio) / (Amount of Collateral)`}</p>
        </Box>
      </Column>
    </Indent>
    <h2>{`Liquidation Penalty`}</h2>
    <hr></hr>
    <p>{`A Liquidation Penalty is a fee paid by Vault owners when the value of their collateral reaches the Vault's Liquidation Price.`}</p>
    <p>{`The Liquidation Penalty is added to the Vault’s total outstanding generated DAI when liquidation occurs, which results in more collateral being sold at auction.`}</p>
    <p>{`Proceeds from Liquidation Penalties are put towards the Surplus Auctions, which result in burned MKR.`}</p>
    <p>{`Liquidation Penalties prevent `}<a parentName="p" {...{
        "href": "https://github.com/livnev/auction-grinding/blob/master/grinding.pdf"
      }}>{`Auction Grinding Attacks`}</a>{`, which allow an attacker to break the Maker Protocol's auction mechanism and exploit the Maker Protocol for profit.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      